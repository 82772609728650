<template>
  <div>
    <template>
      <v-app-bar app color="primary" dark class="hidden-sm-and-down">
        {{ app_title }}
        <v-spacer></v-spacer>

        <template v-if="!empty(user)">
          <el-menu
            :default-active="activeMenu"
            class="el-menu-demo"
            mode="horizontal"
            background-color="#1976d2"
            text-color="#ffffff"
          >
            <el-menu-item index="1" @click="showMenu('events')">
              Events
            </el-menu-item>
            <el-menu-item index="2" @click="showMenu('bookings')">
              Bookings
            </el-menu-item>
            <el-menu-item index="3" @click="showMenu('visitor-types')">
              Visitor Types
            </el-menu-item>
            <el-menu-item index="4" @click="showMenu('users')">
              Users
            </el-menu-item>
          </el-menu>
          <v-spacer></v-spacer>
          <v-btn small icon>
            <v-icon light color="white">mdi-magnify</v-icon>
          </v-btn>

          <v-btn small icon color="white">
            <v-icon>mdi-help-circle-outline</v-icon>
          </v-btn>

          <v-menu rounded="lg" offset-y>
            <template v-slot:activator="{ attrs, on }">
              <v-avatar size="24" class="ml-2" v-bind="attrs" v-on="on">
                <img src="../../assets/face.png" />
              </v-avatar>
            </template>
            <v-list dense>
              <v-list-item-group v-model="selectedMenu" color="primary">
                <v-list-item v-for="menu in userMenus" :key="menu.id">
                  <v-list-item-content>
                    <v-list-item-title v-text="menu.text"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="'Log Out'"
                      @click="signOut"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </template>
        <template v-else>
          <v-btn
            small
            class="mr-2"
            color="success"
            @click="gotoLink('/sign-in')"
          >
            SIGN IN
          </v-btn>

          <v-btn small color="red" @click="gotoLink('/register')">
            REGISTER
          </v-btn>
        </template>
      </v-app-bar>
    </template>
  </div>
</template>

<script>
import Vue from "vue";
import { Menu, MenuItem } from "element-ui";
Vue.use(Menu);
Vue.use(MenuItem);
export default {
  components: {},
  data() {
    return {
      activeMenu: "0",
    };
  },
  created() {},
  methods: {
    gotoLink(link) {
      this.$router.push(link);
    },
    showMenu(item) {
      console.log(item);
    },
  },
  name: "Header",
};
</script>
<style>
.el-menu.el-menu--horizontal {
  border-bottom: none !important;
}
.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 2px solid #ffffff;
  color: #303133;
}
</style>
